@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/variables";

.menu-mobile {
  position: fixed;
  top: var(--header-menu-height-mobile);
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  width: 100vw;
  z-index: 1100;
  background-color: var(--primary-white);
  transform: translateX(-110%) skewX(-4deg);
  transition: transform .5s var(--cubic-bezier);
  will-change: transform;

  &.active {
    transform: translateX(0) skewX(0);
  }

  @include breakpoints.media-breakpoint-up(lg) {
    display: none;
  }

  & nav.container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &-navlist {
    flex: 1;
    padding-left: calc(var(--bs-gutter-x) / 2) !important;
    padding-right: calc(var(--bs-gutter-x) / 2) !important;

    &-item {
      padding-top: variables.$spacer * 0.75;
      padding-bottom: variables.$spacer * 0.75;
      font-size: 18px;
      font-weight: 600;

      &:first-child {
        border-top: 1px solid var(--primary-grey4);
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--primary-grey4);
      }

      &:last-child {
        margin-top: variables.$spacer * 2;
        text-align: center;

        // iPhone 5 / SE exception
        @media only screen and (max-height: 568px) {
          margin-top: variables.$spacer;
        }
      }
    }

    &-link {
      width: 100%;
      display: inline-block;
      text-align: left;
      color: inherit;
      cursor: pointer;
      transition: color .25s var(--cubic-bezier);

      &:hover,
      &.active {
        color: var(--primary-darkgreen);
      }

      &:hover,
      &.active {
        & .chevron-down * g {
          fill: var(--primary-darkgreen);
        }
      }

      & .chevron-down {
        vertical-align: bottom;
        transition: transform .15s var(--cubic-bezier);

        & * g {
          transition: fill .25s var(--cubic-bezier);
        }
      }

      &.toggle.is-open {
        color: var(--primary-green);

        // Rotate the arrow
        & .chevron-down {
          transform: rotate(180deg);

          & * g {
            fill: var(--primary-green);
          }
        }
      }
    }
  }

  &-toggle {
    padding-top: variables.$spacer;

    & li {
      margin-top: variables.$spacer;
      margin-bottom: variables.$spacer;

      &:not(:last-of-type) {
        margin-top: 0;
        padding-bottom: 1rem;
        border-bottom: 1px solid var(--primary-grey4);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      transition: color .25s var(--cubic-bezier);

      &:hover,
      &.active {
        color: var(--primary-darkgreen);
      }

      & svg {
        margin-right: variables.$spacer;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    padding-top: variables.$spacer * 2;
    padding-bottom: variables.$spacer;

    // iPhone 5 / SE exception
    @media only screen and (max-height: 568px) {
      padding-top: variables.$spacer;
    }

    &-legals {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-top: variables.$spacer * 2;
      @include mixins.globalFontStyle('caption');

      // iPhone 5 / SE exception
      @media only screen and (max-height: 568px) {
        padding-top: variables.$spacer * 0.5;
      }

      @media only screen and (max-width: 360px) {
        flex-direction: column;
        align-items: center;
      }

      & > li {
        margin-left: variables.$spacer / 2;
        margin-right: variables.$spacer / 2;
      }
    }
  }
}
