@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.filters {
  @include grid.make-col-ready();
  margin-bottom: variables.$spacer * 1.5;

  @include breakpoints.media-breakpoint-up(lg) {
    margin-bottom: variables.$spacer * 4;
  }

  & p {
    margin: 0.5rem 0;
  }

  &-list {
    display: flex;
    flex-direction: column;

    @include breakpoints.media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &-item {
      margin-bottom: 0.25rem;

      @include breakpoints.media-breakpoint-up(lg) {
        &:not(:first-of-type) {
          margin-left: 1.5rem;
          margin-bottom: 0;
        }
      }

      & a.is-active {
        font-weight: 900;
      }
    }
  }
}