@use "mixins/grid";
@use "variables";

// Row
//
// Rows contain your columns.

@if variables.$enable-grid-classes {
  .row {
    @include grid.make-row();

    > * {
      @include grid.make-col-ready();
    }
  }
}