@use "../variables";

// Container mixins

@mixin make-container($padding-x: variables.$container-padding-x) {
  width: 100%;
  padding-right: $padding-x;
  padding-left: $padding-x;
  margin-right: auto;
  margin-left: auto;
}
