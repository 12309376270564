@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.medias {
  &-content,
  &-image {
    @include grid.make-col-ready();
    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(6);
    }
  }

  &-content {
    &-title {
      margin-top: 1rem;
    }
  }

  &-image {
    @include breakpoints.media-breakpoint-up(lg) {
      padding-right: 2rem;
    }
  }

  &-form {
    & button[type="submit"] {
      margin-top: 1rem;
    }
  }
}