@use "../grid/mixins/breakpoints";
@use "../grid/variables";

%wave {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vw * 0.36);
  width: 100vw;
  padding: 0;
  z-index: -1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  @include breakpoints.media-breakpoint-down(sm) {
    min-height: calc(100vw * 0.5);
    background-position-y: 40%;
    background-size: 175%;
  }

  & .container {
    margin-top: variables.$spacer * 3;
    margin-bottom: variables.$spacer * 3;
  }
}

.wave {
  @extend %wave;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='3000px' height='1067px' viewBox='0 0 1440 512' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='UI' stroke='none' stroke-width='1' fill-rule='evenodd'%3e%3cg fill='rgba(113, 204, 152, 0.2)' transform='translate(0.000000, -118.000000)'%3e%3cpath d='M0,225.346351 C125.231608,163.955464 279.237057,133.260021 462.016349,133.260021 C736.185286,133.260021 704.305177,206.402878 1004.95913,206.402878 C1205.3951,206.402878 1350.40872,176.935252 1440,118 L1440,487.923947 C1339.9455,569.661528 1194.93188,610.530319 1004.95913,610.530319 C720,610.530319 736.675749,545.909558 454.168937,545.909558 C265.831063,545.909558 114.441417,573.939705 0,630 L0,225.346351 Z'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.wave-grey {
  @extend %wave;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='3000px' height='1067px' viewBox='0 0 1440 512' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='UI' stroke='none' stroke-width='1' fill-rule='evenodd'%3e%3cg fill='rgb(246, 246, 251)' transform='translate(0.000000, -118.000000)'%3e%3cpath d='M0,225.346351 C125.231608,163.955464 279.237057,133.260021 462.016349,133.260021 C736.185286,133.260021 704.305177,206.402878 1004.95913,206.402878 C1205.3951,206.402878 1350.40872,176.935252 1440,118 L1440,487.923947 C1339.9455,569.661528 1194.93188,610.530319 1004.95913,610.530319 C720,610.530319 736.675749,545.909558 454.168937,545.909558 C265.831063,545.909558 114.441417,573.939705 0,630 L0,225.346351 Z'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.wave-yellow, .wave.sensibilisation-antigaspi {
  @extend %wave;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='3000px' height='1067px' viewBox='0 0 1440 512' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='UI' stroke='none' stroke-width='1' fill-rule='evenodd'%3e%3cg fill='rgba(241, 196, 0, 0.2)' transform='translate(0.000000, -118.000000)'%3e%3cpath d='M0,225.346351 C125.231608,163.955464 279.237057,133.260021 462.016349,133.260021 C736.185286,133.260021 704.305177,206.402878 1004.95913,206.402878 C1205.3951,206.402878 1350.40872,176.935252 1440,118 L1440,487.923947 C1339.9455,569.661528 1194.93188,610.530319 1004.95913,610.530319 C720,610.530319 736.675749,545.909558 454.168937,545.909558 C265.831063,545.909558 114.441417,573.939705 0,630 L0,225.346351 Z'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.wave-blue, .wave.conseil-formations {
  @extend %wave;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='3000px' height='1067px' viewBox='0 0 1440 512' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='UI' stroke='none' stroke-width='1' fill-rule='evenodd'%3e%3cg fill='rgba(116, 209, 234, 0.2)' transform='translate(0.000000, -118.000000)'%3e%3cpath d='M0,225.346351 C125.231608,163.955464 279.237057,133.260021 462.016349,133.260021 C736.185286,133.260021 704.305177,206.402878 1004.95913,206.402878 C1205.3951,206.402878 1350.40872,176.935252 1440,118 L1440,487.923947 C1339.9455,569.661528 1194.93188,610.530319 1004.95913,610.530319 C720,610.530319 736.675749,545.909558 454.168937,545.909558 C265.831063,545.909558 114.441417,573.939705 0,630 L0,225.346351 Z'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.wave-red, .wave.nouvelles-antennes {
  @extend %wave;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='3000px' height='1067px' viewBox='0 0 1440 512' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='UI' stroke='none' stroke-width='1' fill-rule='evenodd'%3e%3cg fill='rgba(243, 150, 89, 0.2)' transform='translate(0.000000, -118.000000)'%3e%3cpath d='M0,225.346351 C125.231608,163.955464 279.237057,133.260021 462.016349,133.260021 C736.185286,133.260021 704.305177,206.402878 1004.95913,206.402878 C1205.3951,206.402878 1350.40872,176.935252 1440,118 L1440,487.923947 C1339.9455,569.661528 1194.93188,610.530319 1004.95913,610.530319 C720,610.530319 736.675749,545.909558 454.168937,545.909558 C265.831063,545.909558 114.441417,573.939705 0,630 L0,225.346351 Z'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
}

.wave.collecte-invendus-alimentaires,
.wave.conseil-formations,
.wave.sensibilisation-antigaspi,
.wave.nouvelles-antennes {
  @include breakpoints.media-breakpoint-down(sm) {
    background-position-y: 25%;
  }
}