.social-medias {
  display: flex;
  align-items: center;

  & li:not(:last-child) {
    margin-right: 22px;
  }

  & li * g {
    fill: var(--primary-darkgreen);
    transition: fill .15s var(--cubic-bezier);
  }

  &-instagram:hover * g {
    fill: var(--primary-black);
  }

  &-youtube:hover * g {
    fill: #FF0000;
  }

  &-twitter:hover * g {
    fill: #1DA1F2;
  }

  &-linkedin:hover * g {
    fill: #0073B1;
  }

  &-facebook:hover * g {
    fill: #1877F2;
  }
}