@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

/* Card Features */
.card-features {
  @include grid.make-col-ready();
  margin-top: 2rem;

  @include breakpoints.media-breakpoint-up(lg) {
    @include grid.make-col(6);
    margin-top: 2.5rem;
  }

  & .card-feature:last-of-type {
    margin-bottom: 0;
  }

}

.card-feature {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  padding: 1.5rem;
  border: 1px solid var(--primary-grey3);
  min-height: 120px;

  & svg {
    min-width: 55px;
  }

  &-text,
  &-title {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 2rem;
  }
}