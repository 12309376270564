@use "../grid/mixins/breakpoints";

.hero-title {
  margin-top: 2rem;
  margin-bottom: 2rem;

  @include breakpoints.media-breakpoint-up(lg) {
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
}