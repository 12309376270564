@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.blog {
  & .filters {
    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(10);
      @include grid.make-col-offset(1);
    }
  }
}

/* Feed Blog */
.feed-blog {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  &-container {
    flex-direction: column;

    @include breakpoints.media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }
}