@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";

/**
* Global text styles
*/

h1 {
  @include mixins.globalFontStyle("h1");

  @include breakpoints.media-breakpoint-down(md) {
    @include mixins.globalFontStyle("h1_mobile");
  }
}

h2 {
  @include mixins.globalFontStyle("h2");

  @include breakpoints.media-breakpoint-down(md) {
    font-size: 32px;
    line-height: 38px;
  }
}

h3 {
  @include mixins.globalFontStyle("h3");

  @include breakpoints.media-breakpoint-down(md) {
    font-size: 23px;
    line-height: 29px;
  }
}

h4 {
  @include mixins.globalFontStyle("h4");
}

h5 {
  @include mixins.globalFontStyle("h5");
}

p {
  @include mixins.globalFontStyle("paragraph");
  margin: 1rem 0;
}

.quote {
  @include mixins.globalFontStyle("quote");
  color: var(--primary-darkblue);
  font-style: italic;
}

.standfirst {
  @include mixins.globalFontStyle("standfirst");

  @include breakpoints.media-breakpoint-down(md) {
    font-size: 22px;
    line-height: 26px;
  }
}

.tag {
  @include mixins.globalFontStyle('tag');
  color: var(--primary-grey1);
  text-transform: uppercase;

  &:hover {
    font-weight: 900;
  }
}