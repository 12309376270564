@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.mission {
  &-introduction {
    @include grid.make-col-ready();

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(6);
      @include grid.make-col-offset(3);
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    & p:last-of-type {
      margin: 0.5rem 0;
    }
  }


  &-wave {
    margin-bottom: 5rem;

    @include breakpoints.media-breakpoint-down(lg) {
      margin-top: 2rem;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      position: relative;
      overflow-x: hidden;
    }
  }

  &-quote {
    text-align: center;
    @include grid.make-col-ready();
  
    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(8);
      @include grid.make-col-offset(2);
    }
  }

  &-actions {
    @include grid.make-col-ready();

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(10);
      @include grid.make-col-offset(1);
      margin-top: 2rem;
      margin-bottom: 3rem;
    }

    &-card {
      display: flex;
      flex-direction: column;
      margin: 1.5rem auto;

      &:first-of-type {
        margin-top: 2.5rem;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        flex-direction: row;
        max-height: 250px;
      }
  
      & > * {
        display: flex;
        width: 100%;

        @include breakpoints.media-breakpoint-up(lg) {
          width: 50%;
        }
      }

      &-image {
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          max-height: 200px;

          @include breakpoints.media-breakpoint-up(md) {
            max-height: 300px;
          }

          @include breakpoints.media-breakpoint-up(lg) {
            max-height: 100%;
          }
        }

        &-link {
          position: absolute;
          top: 0; 
          left: 0;
          width: 100%;
          height: 100%;
        }
      }

      &-infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 1rem;
        border: 1px solid var(--primary-grey3);
        border-top: 0;

        @include breakpoints.media-breakpoint-up(lg) {
          padding: 2rem;
          border: 1px solid var(--primary-grey3);
          border-left: 0;
        }

        & p {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &-history {
    padding: 2.5rem 0 4rem 0;

    @include breakpoints.media-breakpoint-up(lg) {
      padding: 4rem 0;
    }
  }


/* Ambition */
  &-ambition {
    display: flex;
    align-items: center;
    margin-top: variables.$spacer * 2.5;

    & > div {
      @include grid.make-col-ready();

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(6);
        margin-top: 5rem;

        &:first-child {
          margin-bottom: 0;
        }
      }

      @include breakpoints.media-breakpoint-down(lg) {
        &:first-child {
          order: 12;
        }
      }
    }
  }

/* Expertise */
  &-expertise {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include breakpoints.media-breakpoint-up(lg) {
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    &-cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1.5rem;

      @include breakpoints.media-breakpoint-up(lg) {
        margin-top: 2.5rem;
      }

      & .card-feature {
        width: 100%;
        margin: 0.25rem auto;

        &:nth-child(odd) {
          margin-left: 0;
        }

        &:nth-child(even) {
          margin-right: 0;
        }

        @include breakpoints.media-breakpoint-up(lg) {
          width: calc(50% - (var(--bs-gutter-x) / 2));
        }
      }
    }
  }

  &-solutions-title {
    margin-bottom: 2.5rem;
    text-align: center;
  }
}

.timeline {
  @include grid.make-col-ready();

  @include breakpoints.media-breakpoint-up(lg) {
    @include grid.make-col(10);
    @include grid.make-col-offset(1);
  }

  &-title {
    margin-bottom: 2.5rem;
  }

  &-image,
  &-content {
    display: flex;
    height: 100%;
    width: 100%;

    @include breakpoints.media-breakpoint-up(lg) {
      width: 50%;
      height: 250px;
    }
  }

  &-image {
    height: 150px;

    @include breakpoints.media-breakpoint-up(sm) {
      height: 250px;
    }
  
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: var(--primary-white);

    @include breakpoints.media-breakpoint-up(sm) {
      padding: 1rem;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      padding: 2rem;
    }

    & p {
      margin: 0.5rem 0;
    }
  }

  &-date {
    display: none;
    position: absolute;
    top: 2rem;
    max-width: 8ch;
    text-align: center;

    @include breakpoints.media-breakpoint-up(sm) {
      transform: translateX(-50%);
    }

    &.is-active {
      display: block;
    }
  }

  /* Slider */
  & .controls-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
  }

  & .splide__arrows {
    > div {
      display: flex;
      margin-left: 1.5rem;

      @include breakpoints.media-breakpoint-up(md) {
        margin-left: 2.5rem;
      }
    }

    & .splide__arrow--prev {
      margin-right: 0.5rem;

      @include breakpoints.media-breakpoint-up(md) {
        margin-right: 1rem;
      }
    }
  }

  & .splide__slide {
    display: flex;
    flex-direction: column;

    @include breakpoints.media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  & .splide__pagination {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-right: 1rem;
  }

  & .splide__pagination__page {
    position: relative;
    border-radius: 50%;
    width: 11px;
    height: 11px;
    background-color: var(--primary-darkgreen);
    outline: 0;
    border: 0;
    padding: 0;
    z-index: 1;
    transition: background-color .25s var(--cubic-bezier);

    &.is-active {
      background-color: var(--primary-orange);
    }
  }
  
  & .history-line {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: var(--primary-darkgreen);
    opacity: 0.5;
  }
}

.solutions-previews {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}