@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.cta-contact {
  display: flex;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 4rem;

  @include breakpoints.media-breakpoint-up(lg) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  &-text {
    margin-bottom: 2rem;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(7);
      @include grid.make-col-offset(1);
      margin-bottom: 0;
    }
  }

  &-button {
    text-align: center;
    display: inline-block;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(3);
      @include grid.make-col-offset(1);
      text-align: left;
    }
  }

  & h4 {
    margin-top: 1rem;
  }
}