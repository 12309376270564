.animate-up,
.animate-down,
.animate-left,
.animate-right {
  opacity: 0;
  transition: transform .5s var(--cubic-bezier), opacity .35s ease-in-out;

  &.in-view {
    opacity: 1;
    transform: none;
  }
}

.animate-up {
	transform: translate3d(0, 1rem, 0);
}

.animate-down {
	transform: translate3d(0, -1rem, 0);
}

.animate-left {
	transform: translate3d(-1rem, 0, 0);
}

.animate-right {
	transform: translate3d(1rem, 0, 0);
}