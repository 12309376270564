@use "../grid/mixins/breakpoints";

.feed-events {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include breakpoints.media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;

    @include breakpoints.media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;

      @include breakpoints.media-breakpoint-up(lg) {
        margin-top: 0;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
      margin-left: 1.5rem;

      & button:first-of-type {
        margin-right: 0.5rem;
      }
    }
  }

  & .card-agenda-content {
    border: 1px solid var(--primary-grey4);
    border-top: 0;
  }
}