@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

// .cas-client {

// }

.cas-client-hero {
  align-items: center;

  &-image {
    position: relative;
    text-align: center;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(6);
    }

    & img {
      width: 250px;
      height: 250px;
      object-fit: cover;
      border-radius: 50%;

      @include breakpoints.media-breakpoint-up(md) {
        width: 350px;
        height: 350px;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        width: 425px;
        height: 425px;
      }
    }
  }

  &-content {
    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(6);
    }
  }

  &-category {
    @include mixins.globalFontStyle('tag');
    text-transform: uppercase;
    color: var(--primary-grey1);
  }

  &-title {
    margin-top: 1rem;
  }

  &-subtitle {
    @include mixins.globalFontStyle('standfirst');
    margin: 0.5rem 0 0 0;
  }
}

.cas-client-context {
  margin-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    @include grid.make-col(6);
    @include grid.make-col-offset(3);
    margin-top: 2.5rem;
    margin-bottom: 5rem;
  }

  & p {
    margin-top: 1rem;
  }
}

.cas-client-propositions {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    @include grid.make-col(8);
    @include grid.make-col-offset(2);
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &-title {
    margin-bottom: 2.5rem
  }

  &-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoints.media-breakpoint-up(sm) {
      flex-direction: row;
    }

    &-wrapper {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }

      &:nth-child(2) .cas-client-propositions-card-number {
        background-color: var(--bg-lightred);
      }
  
      &:nth-child(3) .cas-client-propositions-card-number {
        background-color: var(--bg-lightgreen);
      }
  
      &:nth-child(4) .cas-client-propositions-card-number {
        background-color: var(--bg-lightblue);
      }
    }

    &-number {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      width: 100%;
      padding: 0.5rem 0;
      color: var(--primary-darkgreen);
      @include mixins.globalFontStyle('key_figure_mobile');

      @include breakpoints.media-breakpoint-up(sm) {
        min-width: 100px;
        min-height: 110px;
        @include mixins.globalFontStyle('key_figure');
      }
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1rem;
      background-color: var(--primary-white);

      @include breakpoints.media-breakpoint-up(sm) {
        padding: 1rem 2rem;
        min-height: 110px;
      }
    }

    & p {
      margin: 0;
    }
  }
}

.cas-client-impacts {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &-title {
    margin-bottom: 2.5rem
  }

  &-wrapper {
    display: flex;
    flex-direction: column;

    @include breakpoints.media-breakpoint-up(lg) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    & > * {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 1rem 0;
      border-bottom: 1px solid var(--primary-grey4);

      &:first-child {
        padding-top: 0;
        margin-top: 0;
      }

      &:last-child {
        border-bottom: none;
      }

      @include breakpoints.media-breakpoint-up(lg) {
        padding: 2rem 2.5rem 2rem 0;
        margin: 0 0.5rem;

        &:first-child {
          padding: 2rem 2.5rem 2rem 0;
          margin: 0 0.5rem;
        }

        &:last-child {
          border-bottom: 1px solid var(--primary-grey4);
        }
      }
    }
  }

  &-third {
    width: 100%;
    text-align: center;

    @include breakpoints.media-breakpoint-up(lg) {
      flex: 1 1 calc((100% / 3) - 1.5rem);
    }
  }

  &-fourth {
    width: 100%;

    @include breakpoints.media-breakpoint-up(lg) {
      flex: 1 1 calc(50% - 1rem);
    }
  }

  &-highlight {
    margin-bottom: 0.5rem;
    @include mixins.globalFontStyle('key_figure');
    color: var(--primary-darkgreen);
  }

  &-text {
    margin: 0;
  }
}

.cas-client-gallery {
  width: 100%;
  margin-bottom: 2rem;

  @include breakpoints.media-breakpoint-down(up) {
    margin-bottom: 4rem;
  }

  & .splide__track {
    border-radius: 6px;
  }

  @include breakpoints.media-breakpoint-down(md) {
    & .splide__list {
      width: 100%;
    }
  }

  @include breakpoints.media-breakpoint-down(md) {
    & .splide__track {
      border-radius: 0px;
    }
  }

  &-image {
    height: 350px;
    border-radius: 6px;

    @include breakpoints.media-breakpoint-down(md) {
      width: 100%;
      height: auto;
      border-radius: 0;
      margin-bottom: 0.5rem;
    }
  }

  &-arrows {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    & button.splide__arrow--prev {
      margin-right: 1rem;
    }

    @include breakpoints.media-breakpoint-down(md) {
      display: none;
    }
  }
}

.cas-client-quote {
  display: flex;
  flex-direction: column;

  @include breakpoints.media-breakpoint-up(lg) {
    flex-direction: row;
  }

  &-container {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  &-meta {
    margin-bottom: 1rem;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(2);
      @include grid.make-col-offset(2);
      margin-bottom: 0;
    }
  }

  & .quote {
    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(7);
      @include grid.make-col-offset(0);
    }

    @include breakpoints.media-breakpoint-up(xl) {
      @include grid.make-col(6);
      @include grid.make-col-offset(1);
    }
  }

  &-author {
    margin: 0;
  }

  &-role {
    @include mixins.globalFontStyle('tag');
    text-transform: uppercase;
    color: var(--primary-grey1);
  }
}