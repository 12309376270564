main[data-barba="container"] {
  overflow-x: hidden;
}

.default-article {
  margin-bottom: 2.5rem;

  & > a {
    border-bottom: 1px solid var(--primary-black);
  }
}
