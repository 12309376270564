@use "../grid/mixins/breakpoints";
@use "../grid/variables";

.hero-wave {
  margin-bottom: 50px;
  padding-bottom: variables.$spacer * 2;
  background-color: var(--bg-lightgrey);
  transform: translateY(50px);
  z-index: -1;

  @include breakpoints.media-breakpoint-up(sm) {
    margin-bottom: 100px;
    padding: 0 0 4rem 0;
    transform: translateY(100px);
  }

  @include breakpoints.media-breakpoint-up(md) {
    margin-bottom: 100px;
    padding: 0 0 5rem 0;
    transform: translateY(100px);
  }


  & > svg {
    position: absolute;
    max-width: 100%;
    height: auto;
    z-index: -1;
    transform: translateY(-50px);

    @include breakpoints.media-breakpoint-up(sm) {
      transform: translateY(-100px);
    }
  
    @include breakpoints.media-breakpoint-up(md) {
      transform: translateY(-125px);
    }
  }

  & svg g {
    fill: var(--bg-lightgrey);
  }
}