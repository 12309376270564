@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";

/* MailChimp */
#mc_embed_signup_scroll {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  @include breakpoints.media-breakpoint-up(sm) {
    flex-direction: row;
  }

  @include breakpoints.media-breakpoint-up(lg) {
    flex-direction: column;
  }

  @include breakpoints.media-breakpoint-up(xl) {
    flex-direction: row;
  }

  /* Input */
  & .floating-label-container {
    min-width: auto;
    margin-bottom: 0;

    @include breakpoints.media-breakpoint-up(md) {
      width: 100%;
    }
  }

  /* Button */
  & #mc-embedded-subscribe {
    width: 100%;
    margin-top: 1rem;
    margin-left: 0;

    @include breakpoints.media-breakpoint-up(sm) {
      width: 100%;
      max-width: fit-content;
      margin-top: 0;
      margin-left: 1rem;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      width: 100%;
      max-width: 100%;
      margin-top: 1rem;
      margin-left: 0;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      width: 100%;
      max-width: fit-content;
      margin-top: 0;
      margin-left: 1rem;
    }
  }
}