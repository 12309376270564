@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.card-blog {
  height: 100%;
  padding: 1rem 1rem * 1.5;
  background-color: var(--primary-white);

  @include breakpoints.media-breakpoint-up(lg) {
    padding: 2rem 2.5rem;
  }

  &-wrapper {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(10);
      @include grid.make-col-offset(1);
    }
  }

  &-small-wrapper {
    @include grid.make-col-ready();

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      @include grid.make-col(4);
       &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  & h3 {
    margin-top: 0.5rem;
  }

  & p {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}