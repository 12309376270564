@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.card-cas-client-wrapper {
  @include grid.make-col-ready();
  margin: variables.$spacer 0;

  @include breakpoints.media-breakpoint-up(md) {
    @include grid.make-col(6);
  }

  @include breakpoints.media-breakpoint-up(xl) {
    @include grid.make-col(4);
  }
}

.card-cas-client {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background-color: var(--primary-white);

  &-image {
    position: relative;
    width: 100%;
    height: 200px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &-link {
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.5rem;
    border: 1px solid var(--primary-grey4);
  }

  &-brand {
    @include mixins.globalFontStyle('tag');
    color: var(--primary-grey1);
    text-transform: uppercase;
    margin-bottom: 0.25rem;
  }

  &-title {
    margin-bottom: 1.5rem;
  }

  & .btn-text {
    position: relative;
    z-index: 10;
  }
}

.card-cas-client-large {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    height: 100%;
    background-color: var(--primary-white);

    @include breakpoints.media-breakpoint-up(lg) {
      flex-direction: row;
      max-height: 350px;
    }
  }

  &-image {
    width: 100%;
    height: 100%;
    max-height: 325px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      width: 50%;
    }
  }

  &-content {
    width: 100%;
    padding: 1.5rem;

    & h3 {
      margin-bottom: 1.5rem;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      width: 50%;
      padding: 2.5rem;
    }
  }

  &-logo {
    height: 75px;
  }

  & .btn-text {
    position: relative;
    z-index: 10;
  }
}