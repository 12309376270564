@use "../grid/variables";

.agenda {
  h2 {
    margin-bottom: variables.$spacer;

    &:not(:first-child) {
      margin-top: variables.$spacer * 4;
    }
  }
}