@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.solution {
  &-title {
    margin-top: 0;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  &-hero {
    align-items: center;
    padding-top: 4rem;

    &-content {
      margin-top: 1rem;
      order: 12;

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(5);
        order: 1;
        margin-top: 0;
      }

      & p {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }
    }

    &-image {
      order: 1;

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(6);
        @include grid.make-col-offset(1);
        order: 12;
      }

      & svg {
        max-height: 330px;

        @include breakpoints.media-breakpoint-up(lg) {
          max-height: 396px;
        }
      }
    }
  }

  &-content {
    margin-top: 0;
    margin-bottom: 0;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(6);
      @include grid.make-col-offset(3);
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    &-nouvelles-antennes {
      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(8);
        @include grid.make-col-offset(2);
      }
    }

    &-description {
      margin-top: 0.5rem;
    }

    & h4 {
      margin-top: 2rem;
    }
  }
}

.solution-mainfeature {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    flex-direction: row;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &-image {
    max-width: 100%;
    height: 250px;

    @include breakpoints.media-breakpoint-up(md) {
      height: 350px;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      max-width: 40%;
      height: 400px;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 1rem;
    background-color: var(--bg-lightgrey);

    @include breakpoints.media-breakpoint-up(lg) {
      padding-left: 4rem;
      padding-right: 4rem;
    }

    &-wrapper {
      width: 100%;
      padding: 1rem;
      background-color: var(--primary-white);

      @include breakpoints.media-breakpoint-up(md) {
        padding: 2rem 1.5rem;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        max-width: 780px;
      }
    }

    & p {
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}

.solution-keyfigures {
  justify-content: space-around;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    border-bottom: 1px solid var(--primary-grey4);

    &-wrapper {
      margin-top: 1rem;

      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(3);
        margin-top: 0;
      }
    }

    & p {
      margin-top: 0.25rem;
      margin-bottom: 1rem;
    }
  }

  &-number {
    margin-top: 0.5rem;
    font-size: 56px;
    color: var(--primary-darkblue);
  }
}

.solution-gallery {
  width: 100%;
  margin-top: 3rem;

  &-image {
    height: 350px;
    border-radius: 6px;
  }

  &-arrows {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;

    & button.splide__arrow--prev {
      margin-right: 1rem;
    }
  }
}

.solution-featured-image {
  height: 350px;

  @include breakpoints.media-breakpoint-up(lg) {
    height: 450px;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
}

.solution-feed-cases {
  & h2 {
    margin-bottom: 1rem;
  }

  & .card-cas-client-large-content {
    height: 100%;
    border-bottom: 1px solid var(--primary-grey4);
    border-left: 1px solid var(--primary-grey4);
    border-right: 1px solid var(--primary-grey4);

    @include breakpoints.media-breakpoint-up(lg) {
      border-top: 1px solid var(--primary-grey4);
      border-bottom: 1px solid var(--primary-grey4);
      border-left: 0;
      border-right: 1px solid var(--primary-grey4);
    }
  }
}

.solution-solutions-feed {
  &-title {
    margin-bottom: 3rem;
    text-align: center;
  }
}

.solution-workshop {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;

  @include breakpoints.media-breakpoint-up(lg) {
    padding-top: 5rem;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
  }

  &-title {
    margin-bottom: 2.5rem;
    text-align: center;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(10);
      @include grid.make-col-offset(1);
      flex-direction: row;
      height: 250px;
    }
  }

  &-image {
    width: 100%;
    height: 175px;

    @include breakpoints.media-breakpoint-up(lg) {
      width: 50%;
      height: auto;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 2rem 1.5rem 1.5rem;
    background-color: var(--primary-white);

    @include breakpoints.media-breakpoint-up(lg) {
      width: 50%;
    }

    & .tag:hover {
      font-weight: 600;
    }

    & h4 {
      margin-top: 0.5rem;
    }
  }

  &-gallery {

    @include breakpoints.media-breakpoint-down(lg) {
      margin-top: 2.5rem;
    }

    & .splide__arrows {
      display: flex;
      justify-content: flex-end;
      position: relative;
      width: 100%;
      margin-top: 1rem;

      @include breakpoints.media-breakpoint-up(lg) {
        position: absolute;
        justify-content: space-between;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0;
      }

      & .splide__arrow {
        background-color: var(--bg-lightgrey);
        z-index: 1;

        &:first-child {
          margin-right: 0.5rem;
        }

        @include breakpoints.media-breakpoint-up(md) {
          &:first-child {
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.solution-offers {
  &-gallery {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;

    @include breakpoints.media-breakpoint-up(lg) {
      margin-bottom: 5rem;
    }
  }

  &-item {
    height: 210px;
    padding: 1.5rem 1rem 1rem 1rem;
    border: 1px solid var(--primary-grey4);
    text-align: center;

    & p {
      font-size: 16px;
      line-height: 1.15;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      height: 235px;
    }
  }
}
