@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.article {
  &-header {
    margin-top: 2rem;
    margin-bottom: 2rem;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(8);
      margin-top: 4rem;
      margin-bottom: 4rem;
    }

    &-meta {
      @include mixins.globalFontStyle("tag");
      color: var(--primary-grey1);
      text-transform: uppercase;
    }

    &-title {
      margin-top: 1rem;
    }

    &-intro {
      @include mixins.globalFontStyle("standfirst");
      margin-top: 1rem;
    }
  }

  &-featured-image {
    height: 50vh;
    width: 100%;

    @include breakpoints.media-breakpoint-up(lg) {
      height: 75vh;
    }

    & img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &-content {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(8);
      @include grid.make-col-offset(2);
      margin-top: 5rem;
      margin-bottom: 5rem;
    }

    & h2:not(:first-child) {
      display: inline-block;
      margin-top: 1.5rem;
    }

    & p {
      line-height: 1.45;
    }

    & p a {
      text-decoration: underline;
      word-break: break-all;
    }
  }
}