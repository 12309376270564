@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";
@use "../grid/variables";

.card-agenda-wrapper {
  @include grid.make-col-ready();
  margin: variables.$spacer 0;

  @include breakpoints.media-breakpoint-up(md) {
    @include grid.make-col(6);
  }

  @include breakpoints.media-breakpoint-up(xl) {
    @include grid.make-col(4);
  }
}

.card-agenda {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  background-color: var(--primary-white);

  &-image {
    position: relative;
    width: 100%;
    height: 200px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    &-link {
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    height: 100%;
    padding: 1.5rem;

    & p {
      margin-top: variables.$spacer / 2;
    }
  }

  &-date {
    @include mixins.globalFontStyle('tag');
    color: var(--primary-grey1);
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }

  & svg {
    max-height: 200px;
    width: 100%;
  }

  & .btn-text-orange {
    position: relative;
    z-index: 10;
  }
}