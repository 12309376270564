.collapse {
  display: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height .25s var(--cubic-bezier), 
              opacity .25s var(--cubic-bezier);

  &.is-visible {
    display: block;
    height: auto;
  }
}