$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

$enable-grid-classes: true !default;
$container-padding-x: 1rem !default;