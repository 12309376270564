@use "sass:map";
@use "../base/font-styles";

/**
* Use this mixin to add font style to a given element.
* $name refer to font style available in:
* src/css/base/_font-styles.scss
*/
@mixin globalFontStyle($name) {
  @if( map.has-key( font-styles.$globalFontStyle, $name)==false) {
    @error "Invalid font style name #{$name}";
  }
  @else {
    $fontStyle: map.get( font-styles.$globalFontStyle, $name);
    font-weight: map.get( $fontStyle, "fontWeight" );
    font-size: map.get( $fontStyle, "fontSize" );
    letter-spacing: map.get( $fontStyle, "letterSpacing" );
    line-height: map.get( $fontStyle, "lineHeight" );
  }
}