@use "sass:math";
@use "sass:meta";
@use "../variables";

/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-row($gutter: variables.$grid-gutter-width) {
  --bs-gutter-x: #{$gutter};
  --bs-gutter-y: 0;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1); // stylelint-disable-line function-blacklist
  margin-right: calc(var(--bs-gutter-x) / -2); // stylelint-disable-line function-blacklist
  margin-left: calc(var(--bs-gutter-x) / -2); // stylelint-disable-line function-blacklist
}

@mixin make-col-ready($gutter: variables.$grid-gutter-width) {
  // Add box sizing if only the grid is loaded
  box-sizing: if(meta.variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we set the width
  // later on to override this initial width.
  flex-shrink: 0;
  width: 100%;
  max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
  padding-right: calc(var(--bs-gutter-x) / 2); // stylelint-disable-line function-blacklist
  padding-left: calc(var(--bs-gutter-x) / 2); // stylelint-disable-line function-blacklist
  margin-top: var(--bs-gutter-y);
}

@mixin make-col($size, $columns: variables.$grid-columns) {
  flex: 0 0 auto;
  width: math.percentage($size / $columns);
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size, $columns: variables.$grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, math.percentage($num));
}
