@use "../grid/mixins/breakpoints";

.dynamic-content {
  margin-top: var(--header-menu-height); // As Header Menu is fixed, we need some space
  opacity: 0;
  transition: opacity .75s cubic-bezier(0.25, 0.1, 0.25, 1);

  @include breakpoints.media-breakpoint-down(lg) {
    margin-top: var(--header-menu-height-mobile); // As Header Menu is fixed, we need some space
  }

  &.leave {
    opacity: 0;
    transition: opacity .75s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  &.enter {
    opacity: 1;
  }
}