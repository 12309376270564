@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.contact-form {
  &-title {
    @include breakpoints.media-breakpoint-up(xl) {
      @include grid.make-col(4);
    }

    @include breakpoints.media-breakpoint-up(xxl) {
      @include grid.make-col(3);
    }
  }

  @include breakpoints.media-breakpoint-down(xl) {
    & p:first-child {
      margin-top: 0;
    }
  }

  &-fields {
    margin-top: 0;
    margin-bottom: 2rem;

    @include breakpoints.media-breakpoint-up(xl) {
      @include grid.make-col(6);
      @include grid.make-col-offset(2);
      margin-top: 4rem;
      margin-bottom: 3rem;
    }

    &-half {
      display: flex;
      flex-direction: column;

      @include breakpoints.media-breakpoint-up(sm) {
       flex-direction: row;
      }

      & > div {
        width: 50%;

        @include breakpoints.media-breakpoint-up(sm) {
          width: 100%;
          min-width: auto;
        }

        &:not(:first-child) {
          margin-left: 0;

          @include breakpoints.media-breakpoint-up(sm) {
            margin-left: 0.5rem;
          }

          @include breakpoints.media-breakpoint-up(xl) {
            margin-left: 1.5rem;
          }
        }
      }
    }

    &-error {
      @include breakpoints.media-breakpoint-up(xl) {
        @include grid.make-col(6);
        @include grid.make-col-offset(5);
        margin-top: 0;
      }
    }
  }

  &-optional {
    display: none;

    &.is-visible {
      display: block;
    }
  }

  &-success,
  &-error {
    margin-top: 0;
    margin-bottom: 2rem;

    @include breakpoints.media-breakpoint-up(xl) {
      @include grid.make-col(7);
      @include grid.make-col-offset(1);
      margin-top: 4rem;
      margin-bottom: 3rem;
      padding-bottom: 5rem;
    }

    @include breakpoints.media-breakpoint-up(xxl) {
      @include grid.make-col(6);
      @include grid.make-col-offset(2);
    }

    & h2 {
      margin-top: 1rem;
    }
  }

  &-error {
    margin-bottom: 0;
    padding-bottom: 1.5rem;
  }
}

/* Google Recaptcha */
.grecaptcha-badge { 
  visibility: hidden; 
}

.grecaptcha-legals {
  margin-top: 1rem;
  font-size: 0.85rem;

  & a {
    text-decoration: underline;
  }
}