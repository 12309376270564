@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  @include breakpoints.media-breakpoint-down(xl) {
    flex-direction: column;
    align-items: flex-start;
  }

  &-infos {
    display: flex;

    @include breakpoints.media-breakpoint-down(xl) {
      width: 100%;
      flex-direction: column;
      margin-bottom: 1rem !important;
      padding-bottom: 1rem;
      border-bottom: 1px solid var(--primary-grey4);
    }

    & li {
      margin-right: 2rem;

      @include breakpoints.media-breakpoint-down(xl) {
        margin-bottom: 1rem;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    & li {
      display: flex;
      align-items: center;

      & a {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--primary-black);
      }

      & address {
        font-style: normal;
      }

      & svg {
        margin-right: 0.75rem;
      }
    }
  }
}

.contact {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--bg-lightgrey);

  @include breakpoints.media-breakpoint-up(sm) {
    padding-top: 3rem;
    padding-bottom: 4rem;
  }

  &-card {
    &-wrapper {
      @include breakpoints.media-breakpoint-up(md) {
        @include grid.make-col(10);
        @include grid.make-col-offset(1);
      }
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: var(--primary-white);

    @include breakpoints.media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &-title {
      @include mixins.globalFontStyle("solution");
      color: var(--primary-green);
      text-transform: uppercase;

      @include breakpoints.media-breakpoint-down(lg) {
        margin-top: 1rem;
      }
    }

    & p {
      margin: 4px 0 30px 0;
    }

    & > * {
      width: 100%;

      @include breakpoints.media-breakpoint-up(lg) {
        width: 50%;
      }
    }

    & svg {
      @include breakpoints.media-breakpoint-up(lg) {
        margin-right: 2.5rem;
      }
    }
  }

  &-collapsible-card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    background-color: var(--primary-white);

    @include breakpoints.media-breakpoint-up(md) {
      padding: 1.5rem 2rem;
    }

    & > div {
      width: 80%;
    }

    & p {
      margin: 12px 0 0 0;

      & a {
        border-bottom: 1px solid var(--primary-black);
      }
    }

    &-wrapper {
      @include breakpoints.media-breakpoint-up(md) {
        @include grid.make-col(10);
        @include grid.make-col-offset(1);
      }
    }
  }
}

/* Contact Support */
.contact-support {
  padding: 2rem 0;

  @include breakpoints.media-breakpoint-up(lg) {
    padding: 4rem;
  }

  &-wrapper {
    flex-direction: row;
  }

  &-intro-image,
  &-intro {
    @include grid.make-col-ready();
    @include breakpoints.media-breakpoint-up(lg) {
      @include grid.make-col(6);
    }
  }

  &-intro {
    padding-top: 1rem;

    @include breakpoints.media-breakpoint-up(lg) {
      padding-top: 0;
      padding-left: 2rem;
    }

    &-text {
      @include mixins.globalFontStyle('standfirst');
      margin: 0.5rem 0 1.5rem 0;
    }
  }
}