@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.event {
  &-title {
    margin-top: 1rem;
    margin-bottom: 0;

    &-wrapper {
      margin-top: 2rem;
      margin-bottom: 1rem;

      @include breakpoints.media-breakpoint-up(lg) {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }
  }

  &-content {
    margin-top: 1rem;

    @include breakpoints.media-breakpoint-up(lg) {
      margin-top: 2.5rem;
    }

    &-intro,
    &-text {
      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col(5);
      }
    }

    &-intro {
      @include breakpoints.media-breakpoint-up(lg) {
        @include grid.make-col-offset(1);
      }
    }
  }

  &-hero-image {
    width: 100%;
    max-height: 550px;
    text-align: center;

    @include breakpoints.media-breakpoint-up(xl) {
      max-height: 650px;
    }

    & img {
      height: 100%;
      border-radius: 6px;
    }
  }
}