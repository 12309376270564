@use "../grid/mixins/breakpoints";
@use "../grid/variables";

.glide__arrows {
  display: flex;
  margin-left: variables.$spacer;

  @include breakpoints.media-breakpoint-up(lg) {
    margin-left: variables.$spacer * 1.5;
  }
}

.glide__arrow {
  margin: 0 variables.$spacer / 4;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }
}