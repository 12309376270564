@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";


@mixin button($bgcolor, $bgcolordark) {
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  overflow: hidden;
  color: var(--primary-white);
  padding: 12px 18px;
  border-radius: 24px;
  outline: 0;
  border: 0;
  background-color: $bgcolor;
  @include mixins.globalFontStyle("button");
  text-transform: uppercase;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $bgcolordark;
    border-radius: 24px;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: .25s;
    transition-timing-function: var(--cubic-bezier);
  }

  &:hover:before, 
  &:focus:before, 
  &:active:before {
    transform: scaleX(1);
  }

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.btn {
  @include button(var(--primary-green), var(--primary-darkgreen));
}

.btn-yellow {
  @include button(var(--primary-yellow), #C6A204);
}

.btn-red {
  @include button(var(--primary-red), #B43900);
}

.btn-blue {
  @include button(var(--primary-blue), #03738B);
}

.btn-orange {
  @include button(var(--primary-orange), #C3733E);
}

.btn-conseil-formations {
  @include button(var(--primary-blue), #03738B);
}

.btn-sensibilisation-antigaspi {
  @include button(var(--primary-yellow), #C6A204);
}

.btn-nouvelles-antennes {
  @include button(var(--primary-red), #B43900);
}

@mixin button-rounded() {
  width: 50px;
  height: 50px;
  border: 1px solid var(--primary-grey2);
  border-radius: 100%;
  cursor: pointer;
}

.btn-toggle {
  @include button-rounded();

  &:after {
    content: '\002B';
    position: relative;
    top: -4px;
    color: var(--primary-grey1);
    font-weight: 100;
    font-size: 2rem;
  }
  
  &.active:after {
    content: "\2212";
  }
}

@mixin button-nav() { 
  @include button-rounded();
  border: none;
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity .25s var(--cubic-bezier);

  @include breakpoints.media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
  }

  &[disabled] {
    opacity: 0.25;
    cursor: default;
  }
}

.btn-nav {
  &-left {
    @include button-nav();
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle%3eIcons / o chevron-left%3c/title%3e%3cg id='Icons-/-o-chevron-left' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Group' transform='translate(25.000000, 25.000000) scale(-1, 1) translate(-25.000000, -25.000000) '%3e%3cpath d='M22.8150468,16.0750647 L22.8804867,16.1193818 L22.9655203,16.1967945 L30.8402549,24.1141374 C31.0344967,24.3093779 31.05372,24.6203218 30.8862763,24.8367776 L30.8085023,24.9230272 L22.9305233,32.8395278 C22.717657,33.0534885 22.3725866,33.0534885 22.1597333,32.8395409 C21.9655045,32.6443135 21.9462813,32.3333695 22.1137249,32.1169138 L22.1914989,32.0306642 L29.6828858,24.5016475 L22.1597739,16.9387329 C21.965508,16.7434682 21.9462782,16.4325247 22.1137249,16.216065 L22.1914989,16.1298154 C22.3224814,15.9994293 22.5504869,15.962279 22.746054,16.0411685 L22.8150468,16.0750647 Z' id='Shape' fill='%23000000' fill-rule='nonzero'%3e%3c/path%3e%3ccircle id='Oval' stroke='%23A5A5A5' cx='25' cy='25' r='24.5'%3e%3c/circle%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  }

  &-right {
    @include button-nav();
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='50px' height='50px' viewBox='0 0 50 50' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Icons-/-o-chevron-right' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cpath d='M22.8150468,16.0750647 L22.8804867,16.1193818 L22.9655203,16.1967945 L30.8402549,24.1141374 C31.0344967,24.3093779 31.05372,24.6203218 30.8862763,24.8367776 L30.8085023,24.9230272 L22.9305233,32.8395278 C22.717657,33.0534885 22.3725866,33.0534885 22.1597333,32.8395409 C21.9655045,32.6443135 21.9462813,32.3333695 22.1137249,32.1169138 L22.1914989,32.0306642 L29.6828858,24.5016475 L22.1597739,16.9387329 C21.965508,16.7434682 21.9462782,16.4325247 22.1137249,16.216065 L22.1914989,16.1298154 C22.3224814,15.9994293 22.5504869,15.962279 22.746054,16.0411685 L22.8150468,16.0750647 Z' id='Shape' fill='%23000000' fill-rule='nonzero'%3e%3c/path%3e%3ccircle id='Oval' stroke='%23A5A5A5' cx='25' cy='25' r='24.5'%3e%3c/circle%3e%3c/g%3e%3c/svg%3e");
  }
}

@mixin button-text($color) {
  color: $color;
  background-color: transparent;
  @include mixins.globalFontStyle("button");
  text-transform: uppercase;
  transition: color .25s var(--cubic-bezier);
  cursor: pointer;

  &::after {
    content: '→';
    font-size: 20px;
    margin-left: 6px;
    vertical-align: bottom;
    font-weight: 400;
    transition: margin 0.25s var(--cubic-bezier);
  }

  &:hover {
    &::after {
      margin-left: 10px;
    }
  }
}

.btn-text {
  @include button-text(var(--primary-green));
}

.btn-text-orange {
  @include button-text(var(--primary-orange));
}