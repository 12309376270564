@use "../abstracts/mixins";
@use "../grid/mixins/breakpoints";
@use "../grid/variables";

/* Shared styles */
.floating-label-container,
select,
textarea {
  position: relative;
  width: 100%;
  min-width: 360px;
  max-height: variables.$spacer * 3;
  margin-bottom: variables.$spacer;
  border: solid 1px var(--primary-grey2);
  border-radius: 6px;
  background-color: var(--primary-white);
  font-size: 16px;

  @include breakpoints.media-breakpoint-down(md) {
    min-width: 100%;
  }
}

/* Floating Label container */
.floating-label-container {
  margin-bottom: variables.$spacer;
  // padding: 0px 22px;

  &.invalid {
    border: solid 1px var(--primary-red) !important;
  }

  input {
    width: 100%;
    padding: 20px 22px 10px 22px;
    border-radius: 6px;
    border: none;
    outline: 0;
  }

  label {
    @include mixins.globalFontStyle("caption");
    position: absolute;
    transform-origin: top left;
    transform: translate(22px, 16px) scale(1);
    transition: all .175s var(--cubic-bezier);
    cursor: text;

    &.required::after {
      content: '*';
      position: relative;
      right: -2px;
    }
  }
  
  /** Active Label */
  &.active {
    border: solid 1px var(--primary-darkgreen);

    label {
      transform: translate(22px, 6px) scale(.7);
    }
  }
}

/* Shared styles for Select and Textarea */
select,
textarea {
  padding: 14px 22px;

  &:focus,
  &:active {
    border: solid 1px var(--primary-darkgreen);
    outline: 0;
  }
}

/* Select */
select {
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3cg id='Icons-/-chevron-down' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3e%3cg id='Core-/-Icons-/-chevron-right' transform='translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) translate(8.000000, 5.000000)' fill='%23000000' fill-rule='nonzero'%3e%3cpath d='M0.292893219,0.292893219 C0.65337718,-0.0675907428 1.22060824,-0.0953202783 1.61289944,0.209704612 L1.70710678,0.292893219 L7.70710678,6.29289322 C8.06759074,6.65337718 8.09532028,7.22060824 7.79029539,7.61289944 L7.70710678,7.70710678 L1.70710678,13.7071068 C1.31658249,14.0976311 0.683417511,14.0976311 0.292893219,13.7071068 C-0.0675907428,13.3466228 -0.0953202783,12.7793918 0.209704612,12.3871006 L0.292893219,12.2928932 L5.585,7 L0.292893219,1.70710678 C-0.0675907428,1.34662282 -0.0953202783,0.779391764 0.209704612,0.387100557 L0.292893219,0.292893219 Z' id='Shape'%3e%3c/path%3e%3c/g%3e%3c/g%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: calc(100% - 22px) center;
  appearance:none;
  cursor: pointer;
}

/* Textarea */
textarea {
  min-height: variables.$spacer * 6;
}

/* Success and errors */
.error {
  color: var(--primary-red);
}