@use "../grid/mixins/breakpoints";
@use "../grid/mixins/grid";

.decorations-wrapper {
  position: relative;
}

.decoration {
  display: none;
  position: absolute;
  z-index: 10;

  @include breakpoints.media-breakpoint-up(lg) {
    display: block;
  }

  &.fruit {
    & svg {
      height: 250px;
    }
  }

  &.cta-contact {
    &-dots {
      top: 10%;

      @include breakpoints.media-breakpoint-up(lg) {
        left: 1rem;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        left: 2.5rem;
      }

      @include breakpoints.media-breakpoint-up(xxl) {
        left: 5rem;
      }
    }

    &-line {
      right: 5%;
      bottom: -5%;
      display: block;

      @include breakpoints.media-breakpoint-up(lg) {
        right: 5%;
        bottom: -10%;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        right: 10%;
      }

      @include breakpoints.media-breakpoint-up(xxl) {
        right: 15%;
      }
    }
  }

  &.cas-client {
    &-dots {
      left: 50px;

      @include breakpoints.media-breakpoint-up(xl) {
        left: 125px;
      }

      & svg > g > * {
        fill: var(--primary-yellow);
      }
    }

    &-illu {
      right: 1rem;
      bottom: 10rem;

      @include breakpoints.media-breakpoint-up(xl) {
        right: 5rem;
        bottom: 7.5rem;
      }

      & svg {
        height: 200px;
      }
    }
  }

  &.solution {
    &-watermelon {
      left: -150px;
      top: 15%;

      & svg {
        height: 175px;
      }
    }

    &-watermelon-small {
      right: -100px;
      top: 50%;

      & svg {
        height: 225px;
      }
    }

    &-ananas {
      left: -50px;
      top: 15%;

      & svg {
        height: 225px;
      }
    }

    &-orange {
      right: -50px;
      top: 25%;

      & svg {
        height: 225px;
      }
    }
  }

  &.solution-workshop-triangles {
    right: 5%;
    bottom: -5%;

    @include breakpoints.media-breakpoint-up(lg) {
      right: 5%;
      bottom: -10%;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      right: 10%;
    }

    @include breakpoints.media-breakpoint-up(xxl) {
      right: 15%;
    }

    & svg > g > * {
      fill: var(--primary-green);
    }
  }

  &.mission-ambitions {
    &-line {
      top: -2rem;
      right: 0;

      @include breakpoints.media-breakpoint-up(sm) {
        display: block;
      }
    }

    &-triangles {
      bottom: -1rem;

      @include breakpoints.media-breakpoint-up(sm) {
        display: block;
      }
    }
  }

  &.mission {
    &-citrus {
      left: -50px;
      top: 50%;

      & svg {
        height: 185px;
      }
    }

    &-peach {
      right: -50px;
      top: 25%;

      & svg {
        height: 225px;
      }
    }

    &-watermelon {
      left: -100px;
      top: 50%;

      & svg {
        height: 175px;
      }
    }

    &-kiwi {
      right: -100px;
      top: 50%;

      & svg {
        height: 200px;
      }
    }
  }

  &.ecosystem {
    &-ananas {
      top: 3rem;

      @include breakpoints.media-breakpoint-up(lg) {
        left: -100px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        left: -75px;
      }
    }

    &-watermelon {
      bottom: 1rem;

      @include breakpoints.media-breakpoint-up(lg) {
        right: -100px;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        right: -75px;
      }
    }
  }

  &.home-hero-fruits {
    &-left {
      left: 0;
      bottom: 30%;
      z-index: -1;

      & img {
        max-height: 175px;
      }

      @include breakpoints.media-breakpoint-up(md) {
        display: block;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        bottom: -5%;

        & img {
          max-height: 200px;
        }
      }
    }

    &-right {
      right: 0;
      bottom: 30%;
      z-index: -1;

      & img {
        max-height: 225px;
      }

      @include breakpoints.media-breakpoint-up(md) {
        display: block;
      }

      @include breakpoints.media-breakpoint-up(xl) {
        bottom: -5%;

        & img {
          max-height: 300px;
        }
      }
    }
  }
}