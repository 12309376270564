@use "../grid/mixins/breakpoints";

@include breakpoints.media-breakpoint-down(sm) {
  .solutions .row {
    margin-left: 0;
    margin-right: 0;
  } 
}

.color-collecte-invendus-alimentaires{
  color: var(--primary-green);
}

.color-conseil-formations {
  color: var(--primary-blue);
}

.color-nouvelles-antennes {
  color: var(--primary-red);
}

.color-sensibilisation-antigaspi {
  color: var(--primary-yellow);
}

$circle_radius: 200px;

.half-circle-right {
  position: absolute;
  right: -$circle_radius / 2;
  top: $circle_radius;
  width: $circle_radius;
  height: $circle_radius * 2;
  border-bottom-right-radius: $circle_radius;
  border-top-right-radius: $circle_radius;
  border: 1px dashed var(--primary-orange);
  border-left: 0;
  z-index: -1;
  animation: 1.75s ease-out 1 fadeIn;

  @include breakpoints.media-breakpoint-down(lg) {
    display: none;
  }
}

.half-circle-left {
  position: absolute;
  left: -$circle_radius / 2;
  top: $circle_radius;
  width: $circle_radius;
  height: $circle_radius * 2;
  border-bottom-left-radius: $circle_radius;
  border-top-left-radius: $circle_radius;
  border: 1px dashed var(--primary-orange);
  border-right: 0;
  z-index: -1;
  animation: 1.75s ease-out 1 fadeIn;

  @include breakpoints.media-breakpoint-down(lg) {
    display: none;
  }
}

@keyframes fadeIn { 
  0% { 
    opacity: 0; 
  } 

  75% { 
    opacity: 0;
  }  

  100% { 
    opacity: 1;
  }  
}